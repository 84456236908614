import React from 'react';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { CheckResult } from '@internal/plugin-qm-tech-insights-common';

/**
 * @public
 */
export const BooleanCheck = (props: { checkResult: CheckResult }) => {
  return !!props.checkResult.result ? (
    <CheckCircleOutline color="primary" />
  ) : (
    <ErrorOutlineIcon color="error" />
  );
};

/**
 * @public
 */
export const isBooleanCheckFailed = (checkResult: CheckResult) =>
  !checkResult.result;

import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { CheckResult } from '@internal/plugin-qm-tech-insights-common';
import { TechInsightsClient as TechInsightsClientBase } from '@internal/plugin-qm-tech-insights-common/client';
import { TechInsightsApi } from './TechInsightsApi';

import {
  CheckResultRenderer,
  jsonRulesEngineCheckResultRenderer,
} from '../components/CheckResultRenderer';

/** @public */
export class TechInsightsClient
  extends TechInsightsClientBase
  implements TechInsightsApi
{
  private readonly renderers?: CheckResultRenderer[];

  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
    renderers?: CheckResultRenderer[];
  }) {
    super(options);
    this.renderers = options.renderers;
  }

  getCheckResultRenderers(types: string[]): CheckResultRenderer[] {
    const renderers = this.renderers ?? [jsonRulesEngineCheckResultRenderer];
    return renderers.filter(d => types.includes(d.type));
  }

  isCheckResultFailed(check: CheckResult) {
    const checkResultRenderers = this.getCheckResultRenderers([
      check.check.type,
    ]);
    if (checkResultRenderers[0] && checkResultRenderers[0].isFailed) {
      return checkResultRenderers[0].isFailed(check);
    }
    return true;
  }
}

import type { IdentityApi } from '@backstage/core-plugin-api';

interface QuantumMetricAPI {
  sendEvent(eventId: number, conversion: number, value: string): void;
}

interface Window {
  QuantumMetricAPI?: QuantumMetricAPI;
}

const TEAM_MEMBERSHIP_EVENT_ID = 18;

function getTeam(entArray: string[]) {
  return entArray
    .find((element: string) => element.includes('group'))
    ?.split('/')[1];
}

// Parses supplied JWT token and returns the payload
function parseJwt(token: string): { exp: number } {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(
        c =>
          // eslint-disable-next-line prefer-template
          '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2),
      )
      .join(''),
  );

  const payload = JSON.parse(jsonPayload);

  if (payload && payload.ent) {
    const team = getTeam(payload.ent);
    (window as Window).QuantumMetricAPI?.sendEvent(
      TEAM_MEMBERSHIP_EVENT_ID,
      0,
      team || '',
    );
  }

  return JSON.parse(jsonPayload);
}

// Returns milliseconds until the supplied JWT token expires
function msUntilExpiry(token: string): number {
  const payload = parseJwt(token);
  const remaining =
    new Date(payload.exp * 1000).getTime() - new Date().getTime();
  return remaining;
}

// Calls the specified url regularly using an auth token to set a token cookie
// to authorize regular HTTP requests when loading techdocs
export async function setTokenCookie(url: string, identityApi: IdentityApi) {
  const { token } = await identityApi.getCredentials();
  if (!token) {
    return;
  }

  await fetch(url, {
    mode: 'cors',
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  // Call this function again a few minutes before the token expires
  const ms = msUntilExpiry(token) - 4 * 60 * 1000;
  setTimeout(
    () => {
      setTokenCookie(url, identityApi);
    },
    ms > 0 ? ms : 10000,
  );
}

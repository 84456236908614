import { CatalogClient, EntityFilterQuery } from '@backstage/catalog-client';
import type { CompoundEntityRef } from '@backstage/catalog-model';
import { parseEntityRef } from '@backstage/catalog-model';
import {
  Table as BackstageTable,
  Content,
  ErrorPanel,
  InfoCard,
  Progress,
} from '@backstage/core-components';
import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { EntityRefLink } from '@backstage/plugin-catalog-react';
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LaunchIcon from '@material-ui/icons/Launch';
import React, { useState } from 'react';
import useAsync from 'react-use/esm/useAsync';
import { techInsightsApiRef } from '../../api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }),
);

type ChecksTableProps = {
  entityFilter: EntityFilterQuery;
  checksFilter?: string[];
  failingEntityLimit?: number;
};

type CheckResult = {
  passingEntities: number;
  totalEntities: number;
  failingEntities: string[];
};

type AggergateCheckResult = Record<string, CheckResult>;

export const ChecksTable = (props: ChecksTableProps) => {
  const api = useApi(techInsightsApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);
  const catalogClient = new CatalogClient({ discoveryApi, fetchApi });
  const [open, setOpen] = useState(false);
  const [selectedFailingEntities, setSelectedFailingEntities] = useState<any[]>(
    [],
  ); // State to store failing entities for the current row
  const classes = useStyles();
  const [entityDetails, setEntityDetails] = useState<Record<string, string>>(
    {},
  );

  const entityQueryLimit = 4000;
  const queryEntitiesRequest = {
    filter: props.entityFilter,
    limit: entityQueryLimit,
  };

  const openDialog = (failingEntities: string[]) => {
    setSelectedFailingEntities(failingEntities); // Set the selected row's failing entities
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const { value, loading, error } = useAsync(async () => {
    const aggregateResult: AggergateCheckResult = {};

    const entitiesToCheck = (
      await catalogClient.queryEntities(queryEntitiesRequest)
    ).items.map(entity => {
      return {
        kind: entity.kind,
        namespace: entity.metadata.namespace,
        name: entity.metadata.name,
      } as CompoundEntityRef;
    });

    const filteredChecks = (await api.getAllChecks()).filter(check => {
      return props.checksFilter?.includes(check.id);
    });

    const checkResults = await api.runBulkChecks(
      entitiesToCheck,
      filteredChecks,
    );

    for (const entityResult of checkResults) {
      const entityId = entityResult.entity;
      for (const checkResult of entityResult.results) {
        const checkId = checkResult.check.id;
        if (!aggregateResult[checkId]) {
          aggregateResult[checkId] = {
            totalEntities: 0,
            passingEntities: 0,
            failingEntities: [],
          };
        }

        aggregateResult[checkId].totalEntities += 1;

        if (!checkResult.result) {
          const entityName = entityId.split('/')[1];
          aggregateResult[checkId].failingEntities.push(entityName);

          if (entityName) {
            setEntityDetails(prev => ({
              ...prev,
              [entityName]: entityId,
            }));
          }
          continue;
        }

        aggregateResult[checkId].passingEntities += 1;
      }
    }

    return aggregateResult;
  }, []);

  if (error) {
    return <ErrorPanel error={error} />;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Checked Entities Total</TableCell>
          <TableCell>Checked Entities Pass Rate</TableCell>
          <TableCell>Failed Entities</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading ? (
          <TableRow>
            <TableCell colSpan={4}>
              <Progress />
            </TableCell>
          </TableRow>
        ) : (
          value &&
          Object.keys(value).map(check => {
            const failingEntitiesCount = value[check]?.failingEntities.length;

            const passRate = Math.round(
              (value[check].passingEntities / value[check].totalEntities) * 100,
            );

            const columns = [
              {
                title: 'Failed Entity',
                field: 'entity',
                render: (rowData: any) => {
                  const entityName = rowData.entity;
                  const entityRef = entityDetails[entityName];

                  if (entityRef) {
                    return (
                      <EntityRefLink entityRef={parseEntityRef(entityRef)} />
                    );
                  }

                  return <span>{entityName}</span>;
                },
              },
            ];

            return (
              <>
                <TableRow key={check}>
                  <TableCell>{check}</TableCell>
                  <TableCell>{value[check].totalEntities}</TableCell>
                  <TableCell>{`${passRate}%`}</TableCell>
                  <TableCell>
                    <span style={{ verticalAlign: 'middle' }}>
                      {failingEntitiesCount}
                    </span>{' '}
                    <IconButton
                      aria-label="Open Dialog"
                      title="View Failed Entities"
                      onClick={() => openDialog(value[check].failingEntities)} // Pass the failing entities for this row to the dialog
                    >
                      <LaunchIcon
                        fontSize="small"
                        style={{ verticalAlign: 'middle' }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <Dialog
                  open={open}
                  onClose={closeDialog}
                  aria-labelledby="dialog-title"
                  aria-describedby="dialog-description"
                >
                  <DialogContent>
                    <BackstageTable
                      options={{
                        header: false,
                        pageSize: 10,
                        padding: 'dense',
                        search: false,
                      }}
                      columns={columns}
                      data={selectedFailingEntities.map(entity => ({
                        entity,
                      }))} // Use the selected failing entities from the clicked row
                      title={
                        <TableHead>
                          Failed Entities
                          <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={closeDialog}
                          >
                            <CloseIcon />
                          </IconButton>
                        </TableHead>
                      }
                    />
                  </DialogContent>
                </Dialog>
              </>
            );
          })
        )}
      </TableBody>
    </Table>
  );
};

export const OverviewPage = () => {
  return (
    <Content>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <InfoCard title="Subscription Checks">
            <ChecksTable
              entityFilter={{ kind: 'Subscription' }}
              checksFilter={['bqio', 'wren']}
            />
          </InfoCard>
        </Grid>
        <Grid item md={6}>
          <InfoCard title="Component Checks">
            <ChecksTable
              entityFilter={{ kind: 'component' }}
              checksFilter={['readme', 'techDocsCheck']}
            />
          </InfoCard>
        </Grid>
      </Grid>
    </Content>
  );
};

import { CheckResult } from '@internal/plugin-qm-tech-insights-common';
import React from 'react';
import { BooleanCheck, isBooleanCheckFailed } from './BooleanCheck';

/**
 * Defines a react component that is responsible for rendering a result of a given type.
 *
 * @public
 */
export type CheckResultRenderer = {
  type: string;
  component: (check: CheckResult) => React.ReactElement;
  description?: (check: CheckResult) => string | React.ReactElement;
  isFailed?: (check: CheckResult) => boolean;
};

/**
 * Default renderer for json-rules-engine check results.
 *
 * @public
 */
export const jsonRulesEngineCheckResultRenderer: CheckResultRenderer = {
  type: 'json-rules-engine',
  component: (checkResult: CheckResult) => (
    <BooleanCheck checkResult={checkResult} />
  ),
  isFailed: isBooleanCheckFailed,
};

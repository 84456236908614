import {
  createPlugin,
  createRoutableExtension,
  createApiFactory,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { techInsightsApiRef } from './api/TechInsightsApi';
import { TechInsightsClient } from './api/TechInsightsClient';

/**
 * @public
 */
export const techInsightsPlugin = createPlugin({
  id: 'tech-insights',
  apis: [
    createApiFactory({
      api: techInsightsApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new TechInsightsClient({ discoveryApi, identityApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

/**
 * @public
 */
export const ScorecardInfo = techInsightsPlugin.provide(
  createRoutableExtension({
    name: 'ScorecardInfo',
    component: () =>
      import('./components/ScorecardsInfo').then(m => m.ScorecardInfo),
    mountPoint: rootRouteRef,
  }),
);

/**
 * @public
 */
export const ScorecardsList = techInsightsPlugin.provide(
  createRoutableExtension({
    name: 'ScorecardsList',
    component: () =>
      import('./components/ScorecardsList').then(m => m.ScorecardsList),
    mountPoint: rootRouteRef,
  }),
);

/**
 * @public
 */
export const EntityTechInsightsScorecardContent = techInsightsPlugin.provide(
  createRoutableExtension({
    name: 'EntityTechInsightsScorecardContent',
    component: () =>
      import('./components/ScorecardsContent').then(m => m.ScorecardsContent),
    mountPoint: rootRouteRef,
  }),
);

/**
 * @public
 */
export const EntityTechInsightsScorecardCard = techInsightsPlugin.provide(
  createRoutableExtension({
    name: 'EntityTechInsightsScorecardCard',
    component: () =>
      import('./components/ScorecardsCard').then(m => m.ScorecardsCard),
    mountPoint: rootRouteRef,
  }),
);

/**
 * @public
 */
export const TechInsightsScorecardPage = techInsightsPlugin.provide(
  createRoutableExtension({
    name: 'TechInsightsScorecardPage',
    component: () =>
      import('./components/ScorecardsPage').then(m => m.ScorecardsPage),
    mountPoint: rootRouteRef,
  }),
);

/**
 * @public
 */
export const TechInsightsExcellencePage = techInsightsPlugin.provide(
  createRoutableExtension({
    name: 'TechInsightsExcellencePage',
    component: () =>
      import('./components/ExcellencePage').then(m => m.ExcellencePage),
    mountPoint: rootRouteRef,
  }),
);

/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { FeatureFlagged } from '@backstage/core-app-api';
import {
  Lifecycle,
  Sidebar,
  SidebarContext,
  SidebarDivider,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  sidebarConfig,
} from '@backstage/core-components';
import {
  SearchModalProvider,
  SidebarSearchModal,
} from '@backstage/plugin-search';
import { Settings as SidebarSettings } from '@backstage/plugin-user-settings';
import { Link, Typography, makeStyles } from '@material-ui/core';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import CategoryIcon from '@material-ui/icons/Category';
import LogoCloudCircle from '@material-ui/icons/CloudCircle';
import DataObject from '@material-ui/icons/Code';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import ExtensionIcon from '@material-ui/icons/Extension';
import Flag from '@material-ui/icons/Flag';
import GraphicEq from '@material-ui/icons/GraphicEq';
import HomeIcon from '@material-ui/icons/Home';
import LayersIcon from '@material-ui/icons/Layers';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import ListAltIcon from '@material-ui/icons/ListAlt';
import React, { PropsWithChildren, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useContext(SidebarContext);

  return (
    <div className={classes.root}>
      <Link
        component={NavLink}
        to="/"
        underline="none"
        className={classes.link}
      >
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

const AlphaShorthand = () => <Lifecycle alpha shorthand />;

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SearchModalProvider>
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SidebarSearchModal />
        <SidebarDivider />
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="/" text="Home" />
        <SidebarItem icon={CategoryIcon} to="catalog" text="Catalog" />
        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        {/* <SidebarItem icon={InfrastructureIcon} to="" text="Infrastructure" /> */}
        {/* <SidebarItem icon={LockOpen} to="" text="Access Control" /> */}
        {/* <SidebarItem icon={SubscriptionsIcon} to="" text="Subscriptions" /> */}
        {/* <SidebarItem icon={ListAltIcon} to="qm-ox-scores" text="OX Scores" /> */}
        <FeatureFlagged with="tech-insights">
          <SidebarItem
            icon={EmojiObjectsIcon}
            to="tech-insights"
            text="Excellence"
          />
        </FeatureFlagged>

        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
        <SidebarItem icon={LayersIcon} to="explore" text="Explore" />
        {/* End global nav */}
        <SidebarDivider />
        <SidebarItem icon={Flag} to="qm-feature-flags" text="Feature Flags" />
        <SidebarItem
          icon={LogoCloudCircle}
          to="qm-subscription-manager"
          text="Subscriptions"
        />
        <SidebarItem icon={GraphicEq} to="dxp-search" style={{}}>
          <Typography
            variant="subtitle2"
            style={{
              flex: '3 1 auto',
              fontWeight: 'bold',
              lineHeight: 'auto',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipses',
            }}
          >
            DXP Search <AlphaShorthand />
          </Typography>
        </SidebarItem>
        <SidebarItem icon={DataObject} to="qm-config" text="Configuration" />
        <SidebarItem
          icon={ListAltIcon}
          to="/product-permissions/prod"
          text="Product Flags"
        />
        <SidebarScrollWrapper />
        <SidebarSpace />
        <SidebarDivider />
        <SidebarSettings />
      </Sidebar>
      {children}
    </SidebarPage>
  </SearchModalProvider>
);

import {
  BulkCheckResponse,
  CheckResult,
  FactSchema,
} from '@internal/plugin-qm-tech-insights-common';
import { Check, InsightFacts } from './types';
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import {
  CompoundEntityRef,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import qs from 'qs';

/**
 * Client to fetch data from tech-insights backend
 *
 * @public */
export class TechInsightsClient {
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
  }

  /**
   * Get facts for a specific entity
   * @param entity - a component reference
   * @param facts - a list fact ids to fetch
   * @public
   */
  async getFacts(
    entity: CompoundEntityRef,
    facts: string[],
  ): Promise<InsightFacts> {
    const query = qs.stringify({
      entity: stringifyEntityRef(entity),
      ids: facts,
    });
    return await this.api<InsightFacts>(`/facts/latest?${query}`);
  }

  /**
   * Get all checks.
   * This will not return the actual status, but only metadata. To get the status use the /run endpoint
   * @public
   */
  async getAllChecks(): Promise<Check[]> {
    return this.api('/checks');
  }

  /**
   * Get schemas of facts.
   * Use this for example to understand what the return values will be
   * @public
   */
  async getFactSchemas(): Promise<FactSchema[]> {
    return this.api('/fact-schemas');
  }

  /**
   * Run checks for a specific entity
   * @param entityParams - reference to an entity
   * @param checks - IDs of checks to run
   * @public
   */
  async runChecks(
    entityParams: CompoundEntityRef,
    checks?: string[],
  ): Promise<CheckResult[]> {
    const { namespace, kind, name } = entityParams;
    const requestBody = { checks };
    return this.api(
      `/checks/run/${encodeURIComponent(namespace)}/${encodeURIComponent(
        kind,
      )}/${encodeURIComponent(name)}`,
      {
        method: 'POST',
        body: JSON.stringify(requestBody),
      },
    );
  }

  /**
   * Return checks for multiple entities
   * @param entities - list of entity references
   * @param checks - list of check IDs
   * @public
   */
  async runBulkChecks(
    entities: CompoundEntityRef[],
    checks?: Check[],
  ): Promise<BulkCheckResponse> {
    const checkIds = checks ? checks.map(check => check.id) : [];
    const requestBody = {
      entities,
      checks: checkIds.length > 0 ? checkIds : undefined,
    };
    return this.api('/checks/run', {
      method: 'POST',
      body: JSON.stringify(requestBody),
    });
  }

  private async api<T>(path: string, init?: RequestInit): Promise<T> {
    const url = await this.discoveryApi.getBaseUrl('tech-insights');
    const headers: HeadersInit = new Headers(init?.headers);
    if (!headers.has('content-type'))
      headers.set('content-type', 'application/json');

    const request = new Request(`${url}${path}`, {
      ...init,
      headers,
    });

    return fetch(request, {
      credentials: 'include',
    }).then(async response => {
      if (!response.ok) {
        throw await ResponseError.fromResponse(response);
      }
      return response.json() as Promise<T>;
    });
  }
}

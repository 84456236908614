import { createApiRef } from '@backstage/core-plugin-api';
import {
  CheckResult,
  BulkCheckResponse,
  FactSchema,
} from '@internal/plugin-qm-tech-insights-common';
import {
  Check,
  InsightFacts,
} from '@internal/plugin-qm-tech-insights-common/client';
import { CheckResultRenderer } from '../components/CheckResultRenderer';
import { CompoundEntityRef } from '@backstage/catalog-model';

/**
 * {@link @backstage/core-plugin-api#ApiRef} for the {@link TechInsightsApi}
 *
 * @public
 */
export const techInsightsApiRef = createApiRef<TechInsightsApi>({
  id: 'plugin.techinsights.service',
});

/**
 * API client interface for the Tech Insights plugin
 *
 * @public
 */
export interface TechInsightsApi {
  getCheckResultRenderers: (types: string[]) => CheckResultRenderer[];
  isCheckResultFailed: (check: CheckResult) => boolean;
  getAllChecks(): Promise<Check[]>;
  runChecks(
    entityParams: CompoundEntityRef,
    checks?: string[],
  ): Promise<CheckResult[]>;
  runBulkChecks(
    entities: CompoundEntityRef[],
    checks?: Check[],
  ): Promise<BulkCheckResponse>;
  getFacts(entity: CompoundEntityRef, facts: string[]): Promise<InsightFacts>;
  getFactSchemas(): Promise<FactSchema[]>;
}

import { GroupEntity } from '@backstage/catalog-model';
import { InfoCard, InfoCardVariants } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Box } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { ChecksTable } from './OverviewPage';

const CardTitle = (props: { title: string }) => (
  <Box display="flex" alignItems="center">
    <GroupIcon fontSize="inherit" />
    <Box ml={1}>{props.title}</Box>
  </Box>
);

export const GroupExcellenceCard = (props: { variant?: InfoCardVariants }) => {
  const { entity: group } = useEntity<GroupEntity>();
  const groupEntitiyRef = `${group.kind}:${group.metadata.namespace}/${group.metadata.name}`;

  if (!group) {
    return <Alert severity="error">Group not found</Alert>;
  }

  return (
    <InfoCard
      title={<CardTitle title="Component Excellence Score" />}
      variant={props.variant}
    >
      <ChecksTable
        entityFilter={{
          kind: 'component',
          'relations.ownedBy': groupEntitiyRef,
        }}
        checksFilter={['readme', 'techDocsCheck']}
      />
    </InfoCard>
  );
};
